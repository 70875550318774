import React, { useEffect, useState } from 'react';
import { Button, Page, PageHero, Spinner } from '@punchcard/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import documentAPI from 'api/documentsAPI';
import { AuthContext } from 'context/AuthContext';



const Courses = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { currentUser } = React.useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [courses, setCourses] = useState<TeacherCourseWithUnitsDTO[]>([]);

	useEffect(() => {
		async function getCourses() {
			setLoading(true);
			const documents = await documentAPI.getListCourseDocuments();
			if (documents !== null) {
				setCourses(documents);
			} else {
				console.error('Unable to retrieve feedback list');
			}
			setLoading(false);
		}
		getCourses();
	}, []);

	return (
		<Page className="bg-light px py-2">
			<PageHero title={t('welcome_lyla', { student_name: currentUser.firstName })} />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-12">
						<React.Fragment>
							{loading ? (
								<Spinner />
							) : (
								courses.map((course) => (
									<div key={`course_${course.id}`} className="bg-white p-3 mb-4 rounded-4">
										<div className="fw-bold fs-3">{course.courseName}</div>
										{course.courseUnits.map((unit) => (
											<div key={`unit_${unit.id}`} className="p-3 d-flex flex-row align-items-center justify-content-between">
												<div>{unit.unitName}</div>
												<Button
													className="btn btn-primary"
													onClick={() => navigate(`/course/${course.id}/unit/${unit.id}`, { state: { courseName: course.courseName, unitName: unit.unitName } })}
												>
													{t('view')}
												</Button>
											</div>
										))}
									</div>
								))
							)}
						</React.Fragment>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default Courses;

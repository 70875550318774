import React from 'react';
import classNames from 'classnames';
import { LoadingPage, emptyMessageProps, EmptyData } from '@punchcard/core';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	isEmpty?: undefined;
	isLoading?: boolean;
	classNameEmpty?: string;
}

type EmptyProps = {
	isEmpty: true | false;
	isLoading?: boolean;
	classNameEmpty?: string;
} & emptyMessageProps & React.HTMLAttributes<HTMLDivElement>

const Container = (props: IProps | EmptyProps) => {
	const { className, isEmpty, isLoading, classNameEmpty, ...otherProps } = props;
	const { children, ...loadingProps } = otherProps;
	if (isLoading === true) {
		return (
			<LoadingPage {...loadingProps} />
		);
	}
	if (isEmpty) {
		return (
			<EmptyData {...otherProps as EmptyProps} className={classNames(className, classNameEmpty)} />
		);
	}
	return (
		<div className={classNames('flex-fill', className)} {...otherProps} />
	);
};

export default Container;
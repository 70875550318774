import React from 'react';
import { PDFReaderButtonsTeacher, PDFReaderButtonsStudent } from '@punchcard/core';


interface IProps {
	updateMode: (mode: 'pencil' | 'eraser') => void;
	mode: 'pencil' | 'eraser';
	isTeacherView?: boolean;
	currentActivity?: currentActivityAssessment;
	statusCodeMappingActivity: statusCodeMappingActivity;
	onStatusChange?: (courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) => void;
	setGradeModalOpen: (isOpen: boolean) => void;
	gradeModalOpen: boolean;
}
const PDFReaderButtons = (props: IProps) => {
	const { isTeacherView = false, currentActivity, statusCodeMappingActivity, onStatusChange, setGradeModalOpen, gradeModalOpen } = props;
	if (isTeacherView) {
		return <PDFReaderButtonsTeacher
			currentActivity={currentActivity}
			statusCodeMappingActivity={statusCodeMappingActivity}
			onStatusChange={onStatusChange}
			setGradeModalOpen={setGradeModalOpen}
			gradeModalOpen={gradeModalOpen} />;
	} else {
		return <PDFReaderButtonsStudent {...props} />;
	}
};

export default PDFReaderButtons;

import React, { useContext, useEffect, useState } from 'react';
import { Button, Page, PageHero, Pagination, Container, LoadingScreen } from '@punchcard/core';
import { IconChevronDown, IconChevronUp } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PlaneStarLogo from '@punchcard/core/assets/plane-start-logo.png';
import StudentCard from 'components/students/StudentCard';
import { StudentContext } from 'context/StudentContext';

function Students() {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { students, loading, handleSort, sort } = useContext(StudentContext);
	const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page') || '1', 10));
	const [itemsPerPage, setItemsPerPage] = useState(parseInt(searchParams.get('itemsPerPage') || '25', 10));
	const indexOfLastItem = currentPage * itemsPerPage + itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentStudents = students?.slice(indexOfFirstItem, indexOfLastItem);

	useEffect(() => {
		const pageParam = parseInt(searchParams.get('page') || '1', 10);
		const itemsPerPageParam = parseInt(searchParams.get('itemsPerPage') || '25', 10);

		setCurrentPage(pageParam - 1);
		setItemsPerPage(itemsPerPageParam);
	}, [searchParams]);

	const onPageChange = (page: number) => {
		navigate(`?page=${page + 1}&itemsPerPage=${itemsPerPage}`);
	};

	const onPageSizeChange = (size: number) => {
		navigate(`?page=${currentPage + 1}&itemsPerPage=${size}`);
	};

	if (loading) {
		return (
			<LoadingScreen />
		);
	}

	return (
		<Page className="d-flex flex-column">
			<PageHero title={t('students.your_students')} className="justify-content-start">
				<img src={PlaneStarLogo} alt="Lyla Logo" className="d-block px-2" />
			</PageHero>
			<Container
				className="pb-3 px-3 d-flex flex-column flex-fill"
				classNameEmpty="border rounded mx-3 mb-3"
				isEmpty={!students || students?.length === 0}
				emptymessage={t('students.no_students')}
				descriptionmessage={t('students.description_no_students')}
			>
				<div className="d-flex flex-column justify-content-between">
					<div className="d-flex align-items-center pb-2">
						<h6>{t('students.students')}</h6>
						<Button
							className="btn-icon btn-ghost-primary ms-2 p-0"
							role="button"
							aria-label={sort === 'asc' ? 'Sort students in ascending order' : 'Sort students in descending order'}
							onClick={handleSort}
						>
							{sort === 'desc' ? <IconChevronDown /> : <IconChevronUp />}
						</Button>
					</div>
					{currentStudents?.map(student => (
						<StudentCard
							key={student.id}
							studentId={student.id}
							firstName={student.firstName}
							lastName={student.lastName}
							className="mb-2"
						/>
					))}
					<div className="mt-2">
						<Pagination
							currentPage={currentPage}
							itemsPerPage={itemsPerPage}
							onPageChange={onPageChange}
							onPageSizeChange={onPageSizeChange}
							totalItems={students?.length}
						/>
					</div>
				</div>
			</Container>
		</Page>
	);
}

export default Students;
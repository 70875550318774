import React from 'react';
import { Button } from '@punchcard/core';
import { IconBookmark, IconChevronDown, IconChevronUp, IconImportContacts } from '@punchcard/core/icons';

interface IProps {
	lessonName: string;
	lessonNumber: number;
	activities: TeacherCourseActivityDTO[];
	expanded: boolean;
}

function LessonsAccordion(props: IProps) {
	const { lessonName, lessonNumber, activities, expanded } = props;
	const [expand, setExpand] = React.useState<boolean>(false);

	React.useEffect(() => {
		setExpand(expanded);
	}, [expanded]);

	return (
		<div className="d-flex flex-column bg-quaternary-200 rounded p-3">
			<div className="d-flex justify-content-between align-items-center w-100 ">
				<div>
					<Button className="btn-ghost-primary p-0">
						{expand ? <IconChevronUp onClick={() => setExpand(!expand)} /> : <IconChevronDown onClick={() => setExpand(!expand)} />}
					</Button>
					<IconImportContacts className="ms-3 me-2 icon-27"/>
					<span className="fw-bold">{lessonNumber}: {lessonName}</span>
				</div>
			</div>
			{expand && (
				<div className="d-flex flex-column mt-3">
					{activities.map((activity) => (
						<div key={activity.id} className="d-flex align-items-center ms-5 my-2">
							<IconBookmark className="text-secondary-500" style={{ width: 18 }} />
							<span><small>{activity.activityName}</small></span>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default LessonsAccordion;
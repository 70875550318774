import React from 'react';
import classNames from 'classnames';
import EmptyStudent from 'assets/empty-students.png';
import EmptyResource from 'assets/empty-resources.png';

export interface emptyMessageProps extends React.HTMLAttributes<HTMLElement> {
	emptymessage: string;
	descriptionmessage: string;
	image?: 'empty-students' | 'empty-resources';
	widthdescription?: string;
}

function EmptyData(props: emptyMessageProps) {
	const { emptymessage, descriptionmessage, className, image = 'empty-students', widthdescription = 'w-25' } = props;

	return (
		<div className={classNames('flex-fill d-flex align-items-center justify-content-center flex-column', className)}>
			<img src={image === 'empty-students' ? EmptyStudent : EmptyResource} alt="No students found" />
			<h5 className="mt-4 text-quaternary-800 mb-2 text-center">{emptymessage}</h5>
			<p className={classNames('text-quaternary-800 text-center', widthdescription)}>{descriptionmessage}</p>
		</div>
	);
}

export default EmptyData;

import React from 'react';
import { IconChevronRight } from '@punchcard/core/icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface IProps extends React.HTMLAttributes<HTMLElement> {
    studentId: number;
    firstName: string;
    lastName: string;
}

function StudentCard(props : IProps) {
	const { t } = useTranslation();
	const { studentId, firstName, lastName, className } = props;
	return (
		<div className={classNames('bg-white d-flex justify-content-between align-items-center px-3 py-2 rounded', className)}>
			<div>
				{firstName} {lastName}
			</div>
			<Link
				className="btn-sm btn-ghost-primary"
				to={`${studentId.toString()}/courses?tab=1`}
				state={{ firstName: firstName, lastName: lastName}}>
				{t('view')}
				<IconChevronRight />
			</Link>
		</div>
	);
}

export default StudentCard;
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import SecondSideBar from 'components/navigation/SecondSideBar';
import { Link, useParams } from 'react-router-dom';
import { IconArrowLeft, IconClose } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { Tabs, Page, Container, LoadingScreen, Button } from '@punchcard/core';
import { StudentContext } from 'context/StudentContext';
import StudentCourses from './StudentCourses';

function Courses() {
	const { t } = useTranslation();
	const { studentId } = useParams();
	const { students, loading: loadingStudent } = useContext(StudentContext);
	const [isManageCoursesOpen, setManageCoursesOpen] = useState<boolean>(false);
	const urlSearchParams = new URLSearchParams(location.search);
	const tabId = urlSearchParams.get('tab');

	const studentCoursesRef = React.useRef(null);

	const onCancelManageCourses = () => {
		if (studentCoursesRef.current) {
			(studentCoursesRef.current as any).onCancelClick();
		}
	};

	const tabsList = [
		{
			id: 1, label: t('courses.all_courses')
		},
		{
			id: 2, label: t('courses.details')
		},
	];

	function getCurrentStudent() {
		return students.find(student => student.id.toString() === studentId);
	}

	const student = getCurrentStudent();

	if (loadingStudent) {
		return (
			<LoadingScreen />
		);
	}

	return (
		<Page className="d-flex flex-fill" scrollable={false}>
			<SecondSideBar
				title={t('students.students')}
				items={students?.map((student) => ({ id: student.id, name: `${student.firstName} ${student.lastName}`, to: `/students/${student.id}/courses?tab=1` }))}
			/>
			<Container
				className="scroll-container d-flex flex-column overflow-hidden bg-white"
				isEmpty={!student}
				emptymessage={t('students.student_not_found')}
				descriptionmessage={t('students.description_not_found')}
			>
				<header className="page-hero px-3">
					<div className="d-flex justify-content-between">
						{isManageCoursesOpen ? (
							<Button
								className="btn-ghost-primary p-0 btn-sm"
								onClick={onCancelManageCourses}
								icon={<IconClose className="icon-22"/>}>
								{t('cancel')}
							</Button>
						) : (
							<React.Fragment>
								<Link className="sidebar-item text-dark p-0 rounded d-flex" to="/students?page=1&itemsPerPage=10">
									<IconArrowLeft style={{ width: 18 }} />
									<span className="d-inline-block ms-2">
										{t('navigation.back_to_all_students')}
									</span>
								</Link>
								<Link className="sidebar-item text-dark p-0 rounded d-flex align-items-center fw-bold" to="/students?page=1&itemsPerPage=10">
									<span className="d-inline-block ms-2">
										{t('navigation.close')}
									</span>
									<IconClose style={{ width: 18 }} />
								</Link>
							</React.Fragment>
						)}
					</div>
					<div className="page-hero-title mt-2">
						{student && <h6 className="mb-2">{student.firstName} {student.lastName}</h6>}
					</div>
				</header>
				<Tabs tabs={isManageCoursesOpen ? tabsList.filter(f => f.id !== 2) : tabsList} className="px-3" />
				<div className="bg-light d-flex flex-column flex-fill flex-grow-1 border-top border-quartenary-600 overflow-auto">
					{tabId === '1' && (
						<StudentCourses
							setManageCoursesOpen={setManageCoursesOpen}
							isManageCoursesOpen={isManageCoursesOpen}
							studentId={studentId ?? ''}
							ref={studentCoursesRef}
						/>
					)}
					{tabId === '2' && (
						<div className="p-3 w-100">
						</div>
					)}
				</div>
			</Container>
		</Page>
	);
}

export default Courses;
import React, { useEffect, useRef, useState } from 'react';
import { LoadingScreen, PDFReader } from '@punchcard/core';
import documentAPI from 'api/documentsAPI';
import { useFormContext } from 'react-hook-form';

interface IProps {
	courseId: string | undefined;
	courseUnitId: string | undefined;
	studentId: string | undefined;
	submitDrawing: () => void;
	currentActivity?: currentActivityAssessment;
	statusCodeMappingActivity: statusCodeMappingActivity;
	onStatusChange: (courseActivityId: number | undefined, status: string, type: string | undefined, gradeReceived: number | undefined) => void;
	isActivityOpen: boolean;
}

const DisplayPDF = (props: IProps) => {
	const { studentId, courseId, courseUnitId, currentActivity, statusCodeMappingActivity, onStatusChange, isActivityOpen } = props;
	const [loading, setLoading] = useState(false);
	const pdfData = useRef<File | null>(null);
	const methods = useFormContext<WIPForm>();

	useEffect(() => {
		const downloadPDF = async () => {
			try {
				setLoading(true);
				if (currentActivity) {
					documentAPI.getCourseDocument(currentActivity?.item?.courseId, currentActivity?.item?.courseUnitId)
						.then((res) => {
							pdfData.current = res as File;
							setLoading(false);
						});
				}
			} catch (error) {
				console.error('Error fetching documents:', error);
			}
		};
		downloadPDF();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentActivity]);

	const loadStudentLayer = () => {
		if (courseId && courseUnitId && studentId) {
			documentAPI.getStudentLayer(studentId, courseId, courseUnitId)
				.then(async (res) => {
					const paths = await res.text();
					const jsonData = JSON.parse(paths) as ILayerPaths[];
					methods.reset({ studentLayer: jsonData, undoHistory: [], undoIndex: null, lastAction: null });
				});
		}
	};

	const documentLoadSuccess = () => {
		loadStudentLayer();
	};

	return (
		<div className="scroll-container-wrapper wrapper">
			{loading || pdfData.current === null ? (
				<LoadingScreen />
			) : (
				<div className="d-flex flex-column flex-grow-1 overflow-y-auto">
					<PDFReader
						pdfData={pdfData.current}
						documentLoadSuccess={documentLoadSuccess}
						isTeacherView={true}
						submitDrawing={props.submitDrawing}
						currentActivity={currentActivity}
						statusCodeMappingActivity={statusCodeMappingActivity}
						onStatusChange={onStatusChange}
						isActivityOpen={isActivityOpen}
					/>
				</div>
			)}
		</div>
	);
};

export default DisplayPDF;
import { ActionIcon, Button, Container, DataTable, LoadingScreen, ModalOverlay, Page } from '@punchcard/core';
import { IconAdd, IconArrowLeft, IconClose, IconSave } from '@punchcard/core/icons';
import educatorsApi from 'api/educatorApi';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlockerFunction, Link, useBlocker, useParams } from 'react-router-dom';
import { RowInfo } from 'react-table';

function Educators() {
	const { t } = useTranslation();
	const { educatorId } = useParams();
	const [loading, setLoading] = React.useState(false);
	const [educatorRoster, setEducatorRoster] = React.useState<TeacherStudentRosterDTO | null>(null);
	const [unnasignedStudents, setUnnasignedStudents] = React.useState<TeacherStudentDetailsDTO[]>([]);
	const [isManageCoursesOpen, setManageCoursesOpen] = React.useState<boolean>(false);
	const [showWarningModal, setShowWarningModal] = React.useState<boolean>(false);
	const [isDirty, setIsDirty] = React.useState<boolean>(false);
	const onAddStudent = (row: RowInfo) => {
		setIsDirty(true);
		setEducatorRoster((prevRoster) => {
			if (prevRoster) {
				return {
					...prevRoster,
					studentRoster: [...prevRoster.studentRoster, row.original].sort((a, b) => {
						if (a.lastName < b.lastName) { return -1; }
						if (a.lastName > b.lastName) { return 1; }
						if (a.firstName < b.firstName) { return -1; }
						if (a.firstName > b.firstName) { return 1; }
						return 0;
					}),
				};
			} else {
				return {
					id: 0,
					firstName: '',
					lastName: '',
					studentRoster: [row.original],
				};
			}
		});
		setUnnasignedStudents(unnasignedStudents.filter(student => student.id !== row.original.id));
	};
	const onRemoveStudent = (row: RowInfo) => {
		setIsDirty(true);
		setUnnasignedStudents([...unnasignedStudents, row.original].sort((a, b) => {
			if (a.lastName < b.lastName) { return -1; }
			if (a.lastName > b.lastName) { return 1; }
			if (a.firstName < b.firstName) { return -1; }
			if (a.firstName > b.firstName) { return 1; }
			return 0;
		}),
		);
		setEducatorRoster((prevRoster) => {
			if (prevRoster && educatorRoster) {
				return {
					...prevRoster,
					studentRoster: educatorRoster.studentRoster.filter(student => student.id !== row.original.id),
				};
			} else {
				return {
					id: 0,
					firstName: '',
					lastName: '',
					studentRoster: [row.original],
				};
			}
		});
	};
	const onSaveClick = async () => {
		try {
			if (educatorRoster) {
				setLoading(true);
				const educatorRosterResponse = await educatorsApi.educators.deleteStudentFromEducator(educatorRoster);
				setEducatorRoster(educatorRosterResponse);
				setLoading(false);
				setIsDirty(false);
				setManageCoursesOpen(false);
			}
		} catch (error) {
			console.error('Error saving courses: ', error);
			setLoading(false);
		}
	};

	const columns = (isAdd: boolean = false) => {
		return ([
			{
				Header: 'Id',
				accessor: 'id',
				show: false
			},
			{
				Header: t('educators.name'),
				accessor: 'fullName',
				Cell: (row: RowInfo) => `${row.original.firstName} ${row.original.lastName}`,
				wrapStyle: 'wrap',
			},
			{
				Header: t('sites.sites'),
				accessor: 'siteDetails',
				Cell: (row : RowInfo) => {
					return row.original?.siteDetails?.join(', ');
				},
				wrapStyle: 'wrap',
			},
			{
				accessor: 'Actions',
				Cell: (row: RowInfo) => (
					<React.Fragment>
						{isManageCoursesOpen &&
							(isAdd ? <ActionIcon title="add" icon={IconAdd} onClick={() => onAddStudent(row)} /> : <ActionIcon title="remove" icon={IconClose} onClick={() => onRemoveStudent(row)} />)
						}
					</React.Fragment>
				),
				id: 'Actions',
			}
		]);
	};

	async function getEducatorRoster() {
		try {
			if (educatorId) {
				setLoading(true);
				const roster = await educatorsApi.educators.getEducatorRoster(educatorId);
				roster.studentRoster.sort((a, b) => {
					if (a.lastName < b.lastName) { return -1; }
					if (a.lastName > b.lastName) { return 1; }
					if (a.firstName < b.firstName) { return -1; }
					if (a.firstName > b.firstName) { return 1; }
					return 0;
				});
				setEducatorRoster(roster);

				const unnasignedStudentsResponse = await educatorsApi.educators.getUnnasignedStudents(educatorId);
				unnasignedStudentsResponse.sort((a, b) => {
					if (a.lastName < b.lastName) { return -1; }
					if (a.lastName > b.lastName) { return 1; }
					if (a.firstName < b.firstName) { return -1; }
					if (a.firstName > b.firstName) { return 1; }
					return 0;
				});
				setUnnasignedStudents(unnasignedStudentsResponse);
				setLoading(false);
			}
		}
		catch (error) {
			console.error('Error fetching educator roster: ', error);
			setLoading(false);
		}
	}

	React.useEffect(() => {
		getEducatorRoster();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCancelManageCourses = () => {
		if (isDirty) {
			setShowWarningModal(true);
		} else {
			setManageCoursesOpen(false);
		}
	};

	React.useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDirty]);

	const handleBeforeUnload = (e: BeforeUnloadEvent) => {
		if (isDirty) {
			e.preventDefault();
		}
	};

	const shouldBlock = React.useCallback<BlockerFunction>(
		({ currentLocation, nextLocation }) =>
			isDirty && currentLocation.pathname !== nextLocation.pathname,
		[isDirty]
	);

	const blocker = useBlocker(shouldBlock);

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<Page className="d-flex flex-fill" scrollable={false}>
			<Container
				className="scroll-container d-flex flex-column overflow-hidden bg-white"
				isEmpty={!educatorRoster}
				emptymessage={t('educators.educator_not_found')}
				descriptionmessage={t('educators.description_educator_not_found')}
				image="empty-resources"
			>
				<header className="page-hero px-3">
					<div className="d-flex justify-content-between">
						{isManageCoursesOpen ? (
							<Button
								className="btn-ghost-primary p-0 btn-sm"
								onClick={onCancelManageCourses}
								icon={<IconClose className="icon-22" />}>
								{t('cancel')}
							</Button>
						) : (
							<React.Fragment>
								<Link className="sidebar-item text-dark p-0 rounded d-flex" to="/educators">
									<IconArrowLeft style={{ width: 18 }} />
									<span className="d-inline-block ms-2">
										{t('educators.back_to_all_educators')}
									</span>
								</Link>
							</React.Fragment>
						)}
					</div>
					<div className="page-hero-title mt-2">
						{educatorRoster && <h5 className="mb-2">{educatorRoster?.firstName} {educatorRoster?.lastName}</h5>}
					</div>
				</header>
				<div className="container-fluid scroll-container d-flex flex-column flex-grow-1 border-top border-quaternary-600">
					<div className={'row bg-quaternary-400'}>
						<div className="col-6 d-flex align-items-center">
							<h6>{t('educators.student_roster')}</h6>
						</div>
						<div className={`col-6 d-flex align-items-center ${isManageCoursesOpen ? 'justify-content-between border-start' : 'justify-content-end'}`}>
							{isManageCoursesOpen
								? <React.Fragment>
									<h6>{t('educators.all_students')}</h6>
									<Button onClick={onSaveClick} className="btn-primary btn-sm my-1" icon={<IconSave />} style={{ padding: '4.25px' }}>{t('save')}</Button>
								</React.Fragment>
								: <Button
									className="btn-ghost-primary me-n3 btn-sm"
									onClick={() => setManageCoursesOpen(true)}
									icon={<IconAdd className="icon-22" />}>
									{t('educators.manage_roster')}
								</Button>
							}
						</div>
					</div>
					<div className="row flex-grow-1">
						<div className={`${isManageCoursesOpen ? 'col-6' : 'col'} px-0 d-flex`}>
							<Container
								// isLoading={loadingCourse}
								isEmpty={educatorRoster?.studentRoster?.length === 0}
								emptymessage={t('educators.no_students')}
								descriptionmessage={isManageCoursesOpen ? t('educators.description_no_students_manage') : t('educators.description_no_students')}
								widthdescription="w-50"
								image="empty-resources"
							>
								<DataTable columns={columns()} data={educatorRoster?.studentRoster} sortable={true} className="bg-white" />
							</Container>
						</div>
						{isManageCoursesOpen && (
							<div className="col-6 border-start px-0 ">
								<DataTable columns={columns(true)} data={unnasignedStudents} sortable={true} className="bg-white" />
							</div>
						)}
					</div>
				</div>

				<ModalOverlay
					isOpen={showWarningModal || blocker.state === 'blocked'}
					modalSize="md"
					onRequestClose={() => setShowWarningModal(false)}
					headerChildren={t('discard_changes')}
					confirmButtonChildren={t('confirm')}
					cancelButtonChildren={t('cancel')}
					hideCloseButton={true}
					confirmButtonAction={() => {
						if (blocker.state === 'blocked') {
							blocker.proceed?.();
						} else {
							getEducatorRoster();
						}
						setIsDirty(false);
						setShowWarningModal(false);
						setManageCoursesOpen(false);
					}}
					cancelButtonAction={() => {
						if (blocker.state === 'blocked') {
							blocker.reset?.();
						}
						setShowWarningModal(false);
					}}
				>
					{t('discard_changes_message')}
				</ModalOverlay>
			</Container>
		</Page >
	);
}

export default Educators;
import React from 'react';
import { IconChevronDown, IconChevronUp } from '@punchcard/core/icons';
import classNames from 'classnames';
import ActivitiesSummary from './ActivitiesSummary';
import AssessmentSummary from './AssessmentSummary';

interface IProps {
	openAccordions: number[];
	lesson: StudentCourseLessonDTO | TeacherCourseLessonDTO;
	handleLessonClick: (Id: number) => void;
	handleActivityClick: (index: number | null, currentActivity: currentActivityAssessment) => void;
	activeActivity: number | null;
	statusCodeMappingActivity: statusCodeMappingActivity;
	statusCodeMappingLesson: statusCodeMappingLesson;
	isStudent: boolean;
	lessonIndex: number;
	safeStatuses: string[];
}

function LessonAccordionSummary({
	openAccordions,
	lesson,
	handleLessonClick,
	activeActivity,
	handleActivityClick,
	statusCodeMappingActivity,
	statusCodeMappingLesson,
	isStudent,
	lessonIndex,
	safeStatuses,
}: IProps) {
	const isActive = openAccordions.includes(lesson?.id);
	const statusConfig = statusCodeMappingLesson[lesson?.courseLessonStatus?.statusCode] || {};
	const IconComponent = statusConfig.icon;
	return (
		<div className="mb-2">
			<button
				className={classNames('lesson-accordion', { 'active': isActive })}
				onClick={() => handleLessonClick(lesson?.id)}
			>
				<div className="d-flex align-items-center flex-grow-1 overflow-hidden">
					<div className="d-flex align-items-center flex-shrink-0">
						{isActive ? <IconChevronUp style={{ width: 18, height: 18 }} className="me-2" /> : <IconChevronDown style={{ width: 18, height: 18 }} className="me-2" />}
						{IconComponent && <IconComponent
							style={{ width: 18, height: 18 }}
							className={classNames('me-2', { [statusConfig.className]: !isActive, [statusConfig.activeIconClassName ?? '']: isActive })}
						/>}
					</div>
					<div className="text-truncate flex-grow-1 text-start">
						{lessonIndex + ': ' + lesson?.lessonName}
					</div>
				</div>
				<span className={classNames('status ms-3 flex-shrink-0', !isActive && statusConfig.className)}>
					{statusConfig.status}
				</span>
			</button>
			{isActive && (
				<div className="lesson-accordion-content border border-quaternary-800 rounded-bottom-3">
					{lesson?.courseActivities?.map((activity, index) => (
						<ActivitiesSummary
							key={activity.id}
							activity={activity}
							activeActivity={activeActivity}
							handleActivityClick={handleActivityClick}
							statusCodeMappingActivity={statusCodeMappingActivity}
							isStudent={isStudent}
							activityIndex={index + 1}
							safeStatuses={safeStatuses}
						/>
					))
					}
					{lesson?.courseAssessments?.map((assessment, index) => (
						<AssessmentSummary
							key={assessment.id}
							assessment={assessment}
							activeActivity={activeActivity}
							handleActivityClick={handleActivityClick}
							statusCodeMappingActivity={statusCodeMappingActivity}
							isStudent={isStudent}
							assessmentIndex={lesson?.courseActivities?.length + index + 1}
							safeStatuses={safeStatuses}
						/>
					))
					}
				</div>)
			}
		</div>
	);
}

export default LessonAccordionSummary;
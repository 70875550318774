import axios from 'axios';

const educators = {

	getEducators: async (): Promise<TeacherEducatorDTO[]> => {
		return axios.get('/teacher/educators')
			.then(response => response.data as TeacherEducatorDTO[]);
	},
	getEducatorRoster: async (educatorId: string): Promise<TeacherStudentRosterDTO> => {
		return axios.get(`/teacher/educators/${educatorId}`)
			.then(response => response.data as TeacherStudentRosterDTO);
	},
	getUnnasignedStudents: async (educatorId: string): Promise<TeacherStudentDetailsDTO[]> => {
		return axios.get(`/teacher/educators/${educatorId}/unassignedstudents`)
			.then(response => response.data as TeacherStudentDetailsDTO[]);
	},
	deleteStudentFromEducator: async (educatorRoster: TeacherStudentRosterDTO): Promise<TeacherStudentRosterDTO> => {
		return axios.post('/teacher/educators/update', educatorRoster)
			.then(response => response.data as TeacherStudentRosterDTO);
	}
};
const schoolAttributeSite = {
	getSchoolSiteList: async (): Promise<SchoolAttributeDTO> => {
		const res = await axios.get(`/teacher/schools/attributes/${AttributeType.Site}`, {});
		return res.data as SchoolAttributeDTO;
	},
	getSchoolSiteById: async (siteId: number): Promise<AttributeItemDTO> => {
		const res = await axios.get(`/teacher/schools/attributes/${AttributeType.Site}/${siteId}`, {});
		return res.data as AttributeItemDTO;
	},
	postSchoolSite: async (siteId: number, siteName: string, siteDescription: string): Promise<SchoolAttributeDTO> => {
		const res = await axios.post(`/teacher/schools/attributes/${AttributeType.Site}`, {
			attributeItemId: siteId,
			value: siteName,
			description: siteDescription,
		});
		return res.data as SchoolAttributeDTO;
	},
	deleteSchoolSite: async (siteId: number): Promise<boolean> => {
		const res = await axios.delete(`/teacher/schools/attributes/${AttributeType.Site}/${siteId}`, {});
		return res.data as boolean;
	},
	getSiteUsersBySiteId: async (siteId: number): Promise<AttributeUsersDTO> => {
		const res = await axios.get(`/teacher/schools/attributes/${AttributeType.Site}/${siteId}/users`, {});
		return res.data as AttributeUsersDTO;
	},
	getUnassignedUsersBySiteId: async (siteId: number): Promise<UserDetailsBase[]> => {
		const res = await axios.get(`/teacher/schools/attributes/${AttributeType.Site}/${siteId}/unassigned-users`, {});
		return res.data as UserDetailsBase[];
	},
	addUsersToSite: async (siteId: number, userIds: number[]): Promise<boolean> => {
		const res = await axios.post(`/teacher/schools/attributes/${AttributeType.Site}/${siteId}/users`, {
			userIds,
		});
		return res.data as boolean;
	},
	removeUserFromSite: async (siteId: number, xrefUserId: number): Promise<boolean> => {
		const res = await axios.delete(`/teacher/schools/attributes/${AttributeType.Site}/${siteId}/users/${xrefUserId}`, {});
		return res.data as boolean;
	}
};

const educatorsApi = {
	educators,
	schoolSites: schoolAttributeSite,
};

const enum AttributeType {
	Site = 'site',
}

export default educatorsApi;
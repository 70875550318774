import React, { SVGProps } from 'react';
import classNames from 'classnames';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
	icon: React.ComponentType<SVGProps<SVGSVGElement>>;
	label: string;
	reverse?: boolean;
	collapsed?: boolean;
}

const SidebarItem = (props: IProps) => {
	const { icon: Icon, label, reverse, collapsed, className } = props;

	return (
		<div className={classNames('sidebar-item rounded', className)} role="menu" aria-label={label + 'Button'}>
			{reverse ? (
				<div className="d-flex flex-grow-1 align-items-center justify-content-between py-2 px-3">
					{!collapsed && <span aria-label={label}>{label}</span>}
					<span aria-hidden="true"><Icon /></span>
				</div>
			) : (
				<div className="d-flex flex-grow-1 align-items-center py-2 px-3">
					<span aria-hidden="true"><Icon /></span>
					{!collapsed && <span aria-label={label} className="ms-2">{label}</span>}
				</div>
			)}
		</div>
	);
};

export default SidebarItem;
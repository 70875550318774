import React, { useContext, useEffect, useState } from 'react';
import SecondSideBar from 'components/navigation/SecondSideBar';
import { Link, useParams } from 'react-router-dom';
import { IconArrowLeft, IconClose } from '@punchcard/core/icons';
import { useTranslation } from 'react-i18next';
import { Page, Tabs, Container, LoadingScreen } from '@punchcard/core';
import { StudentContext } from 'context/StudentContext';
import UnitsAccordion from 'components/units/UnitsAccordion';
import coursesApi from 'api/coursesApi';


function Units() {
	const { t } = useTranslation();
	const { studentId, courseId } = useParams();
	const { students, loading: loadingStudent } = useContext(StudentContext);
	const [course, setCourse] = useState<TeacherCourseDTO>();
	const [courses, setCourses] = useState<TeacherCourseDTO[]>([]);
	const [pageLoading, setLoading] = useState<boolean>(false);

	const urlSearchParams = new URLSearchParams(location.search);
	const tabId = urlSearchParams.get('tab');


	const tabsList = [
		{
			id: 1, label: t('units.units')
		},
		{
			id: 2, label: t('units.details')
		},
	];

	function getCurrentStudent() {
		return students.find(student => student.id.toString() === studentId);
	}

	useEffect(() => {
		setLoading(true);
		const fetchData = async (studentId: number, courseId: number) => {
			try {
				if (studentId) {
					const coursesData = await coursesApi.getCoursesByStudentId(studentId);
					setCourses(coursesData);
					if (courseId) {
						const unitsData = await coursesApi.getUnitesByStudentIdAndCourseId(studentId, courseId);
						setCourse(unitsData);
					}
				} else {
					setCourses([]);
				}
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data: ', error);
				setLoading(false);
			}
		};
		fetchData(parseInt(studentId ?? ''), parseInt(courseId ?? ''));
	}, [studentId, courseId]);

	const student = getCurrentStudent();
	if (loadingStudent) {
		return (
			<LoadingScreen />
		);
	}
	return (
		<Page className="d-flex flex-fill" scrollable={false}>
			<SecondSideBar
				title={t('courses.courses')}
				items={courses.map(course => ({ id: course.id, name: course.courseName, to: `/students/${studentId}/courses/${course.id}/units?tab=1` }))}
			/>
			<Container
				className="scroll-container flex-column d-flex overflow-hidden"
				isEmpty={!student}
				emptymessage={t('students.student_not_found')}
				descriptionmessage={t('students.description_not_found')}
			>
				<header className="page-hero px-3">
					<div className="d-flex justify-content-between">
						<Link className="sidebar-item text-dark p-0 rounded d-flex" to={`/students/${studentId}/courses?tab=1`}>
							<IconArrowLeft style={{ width: 18 }} />
							<span className="d-inline-block ms-2">
								{t('units.back_to_courses')}
							</span>
						</Link>
						<Link className="sidebar-item text-dark p-0 rounded d-flex align-items-center fw-bold" to={`/students/${studentId}/courses?tab=1`}>
							<span className="d-inline-block ms-2">
								{t('navigation.close')}
							</span>
							<IconClose style={{ width: 18 }} />
						</Link>
					</div>
					<div className="page-hero-title mt-2">
						{student && <span className="d-inline-block mb-2 fw-bold">{student.firstName} {student.lastName}</span>}
						{student && <h6 className="mb-2">{course?.courseName}</h6>}
					</div>
				</header>
				<Tabs tabs={tabsList} className="px-3" />
				<div className="bg-light d-flex flex-grow-1 border-top border-quartenary-600 overflow-auto">
					{tabId === '1' && (
						<Container
							isEmpty={!course || course?.courseUnits?.length === 0}
							emptymessage={t('units.no_units_for_this_course')}
							descriptionmessage={t('units.description_no_units_for_this_course')}
							className="flex-fill d-flex flex-column p-3"
							isLoading={pageLoading}
						>
							{course?.courseUnits?.map((unit) => {
								return (
									<div key={unit.id} className="mb-2">
										<UnitsAccordion unitId={unit.id} unitName={unit.unitName} unitNumber={unit.unitNumber} lessons={unit.courseLessons} />
									</div>
								);
							})}
						</Container>
					)}
					{tabId === '2' && (
						<div className="p-3 w-100" />
					)}
				</div>
			</Container>
		</Page>
	);
}

export default Units;
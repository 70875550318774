import React from 'react';
import { IconBookmarkFilled, IconChevronRight } from '@punchcard/core/icons';
import classNames from 'classnames';

interface IProps {
	assessment: StudentCourseActivityDTO | TeacherCourseActivityDTO;
	activeActivity: number | null;
	handleActivityClick: (index: number | null, currentActivity: currentActivityAssessment) => void;
	statusCodeMappingActivity: statusCodeMappingActivity;
	isStudent: boolean;
	assessmentIndex: number;
	safeStatuses: string[];
}

function AssessmentSummary({ assessment, activeActivity, handleActivityClick, statusCodeMappingActivity, isStudent, assessmentIndex, safeStatuses }: IProps) {
	const isActive = activeActivity === assessment?.id;
	const hasSkippedTag: boolean = assessment && assessment.courseActivityTags && assessment.courseActivityTags.some(tag => tag.courseActivityTagCode === 'Skipped');
	const isActivityOpen = (
		assessment && (assessment.courseActivityStatus == null || (assessment.courseActivityStatus && safeStatuses.includes(assessment.courseActivityStatus.statusCode)))) ?? true;
	const statusConfig = isActivityOpen && hasSkippedTag ? statusCodeMappingActivity['Skipped'] : statusCodeMappingActivity[assessment?.courseActivityStatus?.statusCode || 'Open'] || {};
	const statusConfigIcon = isActivityOpen && hasSkippedTag &&
		(!assessment.courseActivityStatus || (assessment.courseActivityStatus && assessment.courseActivityStatus.statusCode !== 'ReAttempt'))
		? statusCodeMappingActivity['Skipped']
		: statusCodeMappingActivity[assessment?.courseActivityStatus?.statusCode || 'Open'] || {};

	const IconComponent = statusConfigIcon.icon;
	return (
		<button
			className={classNames('activity', { 'active': isActive })}
			onClick={() => handleActivityClick(assessment?.id, { type: 'Assessment', item: assessment })}
		>
			<div className="d-flex align-items-center flex-grow-1 overflow-hidden">
				<div className="d-flex align-items-center flex-shrink-0">
					{IconComponent && <IconComponent style={{ width: 18, height: 18 }} className={classNames('me-2', statusConfig.className)} />}
				</div>
				<div className="text-truncate flex-grow-1 text-start">
					{assessmentIndex + ': ' + assessment?.activityName}
				</div>
			</div>
			<span className={
				classNames('status ms-3 flex-shrink-0',
					isActive ? statusConfig.activeClassName : statusConfig.className,
					{ 'text-decoration-underline': statusConfig.clickable && (!isActive || !isStudent) }
				)
			}>
				{isActive && isStudent ? (
					<div className="d-flex align-items-center">
						<IconBookmarkFilled className="me-2 text-primary-700" style={{ width: 14, height: 18 }} />
						{statusConfig.activeStatus}
					</div>
				) : (
					statusConfig.status === 'Passed' ? `${assessment.gradeReceived}/${assessment.gradeValue}` : statusConfig.status
				)}
				{statusConfig.clickable && (!isActive || !isStudent) && (
					<IconChevronRight className={`ms-1 ${statusConfig.className}`} style={{ width: 18, height: 18 }} />
				)}
			</span>
		</button>
	);
}

export default AssessmentSummary;
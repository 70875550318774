import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

interface ITab {
	id: number;
	label: string;
}

interface IProps {
	tabs: ITab[];
	className?: string;
}

const Tabs = (props: IProps) => {
	const [, setSearchParams] = useSearchParams();
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);
	const tabId = urlSearchParams.get('tab');

	const handleTabClick = (tab: ITab) => {
		setSearchParams({ tab: tab.id.toString() });
	};

	const handleTabKeyPress = (event: React.KeyboardEvent<HTMLButtonElement>, tab: ITab) => {
		if (event.key === 'Enter' || event.key === ' ') {
			event.preventDefault();
			setSearchParams({ tab: tab.id.toString() });
		}
	};

	React.useEffect(() => {
		setDocTitle(getTabTitle(location, props.tabs));
	}, [location, props.tabs]);

	return (
		<div className={classNames('tab-wrapper d-flex', props.className)}>
			{props.tabs.map((tab) => (
				<button
					className={classNames('tab me-2', { active: tab.id.toString() === tabId })}
					key={tab.id}
					onClick={() => handleTabClick(tab)}
					onKeyDown={(event) => handleTabKeyPress(event, tab)}
					tabIndex={0}
				>
					{tab.label}
				</button>
			))}
		</div>
	);
};

function setDocTitle(value: string | undefined) {
	const titleSuffix = 'LYLA';
	if (value) {
		document.title = `${value} | ${titleSuffix}`;
	} else {
		document.title = titleSuffix;
	}
}

function getTabTitle(location: { search: string }, tabs: ITab[]) {
	let title = '';
	const urlSearchParams = new URLSearchParams(location.search);
	const tabId = urlSearchParams.get('tab');

	const currentTab = tabs.find((tab: ITab) => {
		return location && (tab.id.toString() === tabId);
	});

	if (currentTab) {
		title = currentTab.label;
	}
	return title;
}

export default Tabs;
